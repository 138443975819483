<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title style="justify-content: space-between">
          ЗП Клинеров
          <export-excel
              v-if="$vuetify.breakpoint.width < 480"
              :fetch="prepareExcelData"
              :fields="excelFields"
              :name="'salary_'+filterdate.month+'_'+filterdate.year+'.xls'"

          >
            <v-icon>mdi-download</v-icon>
          </export-excel>
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col style="max-width: 200px">
              <v-select
                  v-model="filterdate.month"
                  :items="$store.state.months"
                  item-value="value"
                  item-text="month"
                  outlined
                  @change="getSalary()"></v-select>
            </v-col>
            <v-col style="max-width: 200px">
              <v-select
                  v-model="filterdate.year"
                  :items="years"
                  item-value="value"
                  outlined
                  @change="getSalary()"></v-select>
            </v-col>
<!--            <v-col v-if="$vuetify.breakpoint.width > 480">-->
<!--              <v-btn>-->
<!--                <export-excel-->
<!--                    :fetch="prepareExcelData"-->
<!--                    :fields="excelFields"-->
<!--                    :name="'salary_'+filterdate.month+'_'+filterdate.year+'.xls'"-->
<!--                >-->
<!--                  Скачать-->
<!--                </export-excel>-->
<!--              </v-btn>-->

<!--            </v-col>-->
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="salary"
              :headers="salaryHeader"
              item-key="id"
              class="cursor-pointer"
              :loading="loading_salary"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="ЗП нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr @click="$eventBus.$emit('openCleaner', item.cleaner.n_id)">
                <td>{{ $root.cleanerInitials(item.cleaner.name) }}</td>
                <td>{{ item.cleans }}</td>
                <td>{{ item.min_cleans_count }}</td>
                <td>
                  <ColoredSum :value="item.penalties" color="#e55353"></ColoredSum>
                </td>
                <td>
                  <ColoredSum :value="item.cleaner.deposit" color="black"></ColoredSum>
                </td>
                <td style="background: #fffac9">
                  <ColoredSum style="font-weight: bold" :value="item.first_half.income" color="black"></ColoredSum>
                </td>
                <td style="background: #fffac9">
                  <ColoredSum :value="item.first_half.salary" color="green"></ColoredSum>
                </td>
                <td style="background: #fffac9">
                  <ColoredSum :value="item.first_half.to_pay" color="red"></ColoredSum>
                </td>
                <td style="background: #c0f6ff">
                  <ColoredSum style="font-weight: bold" :value="item.second_half.income" color="black"></ColoredSum>
                </td>
                <td style="background: #c0f6ff">
                  <ColoredSum :value="item.second_half.salary" color="green"></ColoredSum>
                </td>
                <td style="background: #c0f6ff">
                  <ColoredSum :value="item.second_half.to_pay" color="red"></ColoredSum>
                </td>
                <td>
                  <ColoredSum :value="item.balance" color="red"></ColoredSum>
                </td>
                <td>
                  <ColoredSum :value="item.cash" color="black"></ColoredSum>
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr>
                <th>Итого</th>
                <th>{{ sumField('cleans') }}</th>
                <th>{{ sumField('min_cleans_count') }}</th>
                <th>
                  <ColoredSum :value="sumField('penalties')" color="#e55353"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('cleaner.deposit')" color="black"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('first_half.income')" color="black"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('first_half.salary')" color="green"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('first_half.to_pay')" color="red"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('second_half.income')" color="black"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('second_half.salary')" color="green"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('second_half.to_pay')" color="red"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('balance')" color="red"></ColoredSum>
                </th>
                <th>
                  <ColoredSum :value="sumField('cash')" color="black"></ColoredSum>
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import ColoredSum from "@/components/ColoredSum";

export default {
  name: "Salary",
  components: {
    ColoredSum
  },
  data() {
    let years = [];
    for (let i = 0; i <= this.$moment().format('YYYY') - 2015; i++) {
      years.push((2015 + i).toString())
    }
    return {
      salary: [],
      salaryHeader: [
        {text: 'ФИО', value: 'cleaner_name'},
        {text: 'Кол-во заказов', value: 'cleans'},
        {text: 'Мин уборок', value: 'min_cleans_count'},
        {text: 'Штрафы / Вычеты', value: 'penalties'},
        {text: 'Залог', value: 'cleaner.debit'},
        {text: 'Начислено 1-15', value: 'first_half.income'},
        {text: 'Выдано 1-15', value: 'first_half.salary'},
        {text: 'Остаток 1-15', value: 'first_half.to_pay'},
        {text: 'Начислено 16-%', value: 'second_half.income'},
        {text: 'Выдано 16-%', value: 'second_half.salary'},
        {text: 'Остаток 16-%', value: 'second_half.to_pay'},
        {text: 'Общий остаток', value: 'balance'},
        {text: 'На руках', value: 'cash'},
      ],
      loading_salary: true,
      years,
      filterdate: {
        year: this.$moment().format('YYYY'),
        month: this.$moment().format('M'),
        half: '0',
      },
      excelFields: {
        'ФИО': 'cleaner_name',
        'Кол-во заказов': 'cleans',
        'Мин уборок': 'min_cleans_count',
        'Штрафы / Вычеты': 'penalties',
        'Начисления / Премии': 'awards',
        'Выдано клинеру': 'salary',
        'Фонд': 'fond',
        'Остаток': 'balance'
      }
    }
  },
  methods: {
    getSalary() {
      this.$store.state.server.request('cleaner/paymentsTable', {
        year: this.filterdate.year,
        month: this.filterdate.month,
        half: this.filterdate.half
      }, (data) => {
        this.loading_salary = false
        this.salary = data.response
      }, () => {
        this.loading_salary = false
      })
    },
    sumField(key) {
      let parts = key.split('.')
      let sum = 0;
      this.salary.forEach(salary => {
        sum += parseInt(parts.length === 2 ? salary[parts[0]][parts[1]] : salary[parts[0]]) || 0;
      })
      return sum;
    },
    prepareExcelData() {
      let data = this.salary.slice();
      data.push({
        cleaner_name: 'Итого',
        cleans: this.sumField('cleans'),
        min_cleans_count: this.sumField('min_cleans_count'),
        penalties: this.sumField('penalties'),
        awards: this.sumField('awards'),
        salary: this.sumField('salary'),
        fond: this.sumField('fond'),
        balance: this.sumField('balance'),
      })
      return data;
    }
  },
  mounted() {
    this.getSalary()
  }
}
</script>
<template>
  <span style="white-space: nowrap" :style="`color: ${color}`">
    {{ $root.printCost(value) }}
  </span>
</template>
<script>
export default {
  name: "ColoredSum",
  props: {
    value: {default: '0'},
    color: {default: ''},
  },
  mounted() {
    this.color = this.color || (parseInt(this.value) >= 0 ? 'green' : 'red');
  }
}
</script>